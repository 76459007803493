import React from "react";

function Footer() {
  return (
    <div class="footer container" id="myHeader">
      <h2>Email: Web@roosevelts.dev</h2>
    </div>
  );
}

export default Footer;