import React from "react";

function Header() {
  return (
    <div class="header container" id="myHeader">
      <h1>Roosevelt B. Segura</h1>
    </div>
  );
}

export default Header;
