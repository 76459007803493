import React from "react";

function App() {
  return (
    <div className="App">
      <br />
      <div>
        <h3>Summary</h3>
        <p>
          Experienced IT support representative with great customer service
          skills which are use to provide techinical support. Someone who is
          always in pursuit of new knowledge as well as how to apply them to
          business goals.
        </p>
      </div>

      <div>
        <h3>Skills & Abilities</h3>
        <ul>
          <li>Microsoft 365</li>
          <li>Microsoft Azure</li>
          <li>Jira Service Management</li>
          <li>ServiceNow</li>
          <li>Network Security</li>
          <li>Superior Customer Service</li>
          <li>Web Development (Basic)</li>
          <li>Powershell Scripting (Basic)</li>
          <li>Bilingual (English and Spanish)</li>
        </ul>
      </div>
      <div>
        <h3>Certifications</h3>
        <p>CompTIA Security+ CE Apr/2016 – Apr/2019</p>
        <p>Azure Fundamentals May/2021</p>
      </div>
      <div>
        <h3>Training</h3>
        <ul>
          <li>Windows Server</li>
          <li>Network+</li>
          <li>Security+ </li>
          <li>Linux</li>
        </ul>
      </div>
      <div className="Experience jobs">
        <h3>Experience</h3>
        <br></br>

        <div>
          <h4>
            Senior Help Desk Technician
            <br />
            Jounce Therapeutics
            <br />
            Cambridge, MA
            <br />
            July/2021 – Feb/2022
          </h4>
          <p>
            First point of support across the company for all information
            systems
            <br />
            Create Virtual Machines for end-users and business needs.
            <br />
            Managed Microsoft 365 accounts.
            <br />
            Creation of M365 Teams and email distribution list.
            <br />
            Managed Box accounts and permissions to company’s folders
            <br />
            Troubleshoot SSO issue.
            <br />
            Coordinated with vendors for lab equipment maintenance.
            <br />
            Provides basic training to users as needed.
            <br />
            Allocate equipment for onboarding and offboarding personnel.
            <br />
            Responded to alerts from endpoint protection software.
            <br />
          </p>
        </div>
        <div>
          <h4>
            Desktop Support Analyst
            <br />
            Massachusetts General Hospital
            <br />
            Boston, MA
            <br />
            May/2019 – Jul/2021
          </h4>
          <p>
            Collaborate and communicate effectively with colleagues, and other
            hospital personnel, to facilitate day to day operations.
            <br />
            Troubleshoot / repair hardware and software problem
            <br />
            Install patches / upgrades to Desktop / Office based applications.
            <br />
            Coordinates with System Administrators on mission critical
            application issues.
            <br />
            Provide training and documentation instructions for hardware and
            software operation for clinical systems.
            <br />
            Provide phone, e-mail, on-site, and off-site direct user support as
            required.
            <br />
            Investigates, plans, implements, tests, and debugs operating system
            software.
            <br />
            Troubleshoots and resolves hardware, software, and connectivity
            problems, including user access and component configuration.
            <br />
            Provide advanced technical assistance and maintenance support to
            department and users.
            <br />
            Records and maintains hardware and software inventories for ende
            user license agreements.
            <br />
            Performs problem resolution and provides on-call support for
            computer/network systems.
          </p>
        </div>
        <div>
          <h4>
            IT Helpdesk Support Specialist
            <br />
            Netscout
            <br />
            Westford, MA
            <br />
            Nov/2018 – Mar/2019
          </h4>
          <p>
            Provide desktop end-user support, PC setup/configuration and
            resolution of all IT Client Services requests, for local and remote
            employees.
            <br />
            Provide technical support to end users in support of hardware,
            software, peripherals, and network infrastructure.
            <br />
            Build and deploy various brands of personal computers with
            associated configurations.
            <br />
            Deploy and support VOIP phone hardware and software.
            <br />
            Maintain and enforce corporate hardware, software, and anti-virus
            policies.
            <br />
            Update online helpdesk ticketing system, to track progress and
            status.
            <br />
            Attend regular staff meetings to report on tasks.
            <br />
            Act as a resource for all employees regarding technical questions,
            and training.
          </p>
        </div>
        <div>
          <h4>
            Line Assembler
            <br />
            MilliporeSigma
            <br />
            Danvers, MA
            <br />
            Apr/2018 – Oct/2018
          </h4>
          <p>
            Followed detailed instructions and established procedures during the
            assembly of product.
            <br />
            producing and packaging medical disposables in a biopharmaceutical
            environment.
            <br />
            Maintains a safe and clean working environment by complying with
            procedures, rules, and regulations.
          </p>
        </div>

        <div>
          <h4>
            Help Desk Technician I
            <br />
            1Path
            <br />
            North Andover, MA
            <br />
            Aug/2017 – Jan/2018
          </h4>
          <p>
            Install, modify, and repair computer hardware and software.
            <br />
            Manage Active Directory.
            <br />
            Manage and Troubleshoot Office 365 email.
            <br />
            Troubleshoot MS Windows 7 & 10.
            <br />
            Coordinate with remote users for PC repairs and diagnostics.
            <br />
            Troubleshoot network connectivity issues, working with remote users
            on a corporate network.
          </p>
        </div>
        <div>
          <h4>
            Help Desk Technician I
            <br />
            G4S Secure Integration
            <br />
            Burlington, MA
            <br />
            Oct/2016 – March/2017
          </h4>
          <p>
            Monitor hardware, software and networks.
            <br />
            Used internal ticketing systems to track, manage, review machine
            issues.
          </p>
        </div>
      </div>
      <div>
        <h3>Military</h3>

        <p>
          {" "}
          21W Carpentry Specialist
          <br /> E-4 Specialist
          <br />
          U.S. Army Reserves
          <br />
          Mar/2010 - Mar/2018
        </p>
      </div>
      <div>
        <h3>Education</h3>

        <p>
          Field of Study: Cybersecurity
          <br /> Middlesex Community College
        </p>
      </div>
    </div>
  );
}

export default App;
